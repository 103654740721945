import { render, staticRenderFns } from "./ResetPasswordForm.vue?vue&type=template&id=50c2be3e&scoped=true"
import script from "./ResetPasswordForm.vue?vue&type=script&lang=js"
export * from "./ResetPasswordForm.vue?vue&type=script&lang=js"
import style0 from "./ResetPasswordForm.vue?vue&type=style&index=0&id=50c2be3e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50c2be3e",
  null
  
)

export default component.exports