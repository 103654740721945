<template>
  <div class="poll-wrapper">
    <div class="d-flex justify-space-between align-center">
      <h2 class="poll-header-title">Poll</h2>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
           style="cursor: pointer" @click="closePoll">
        <path d="M18 6C13.7552 9.37699 10.9401 12.5617 6 18" stroke="#0057B7" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 6C8.23219 9.25226 10.383 11.6003 18 18" stroke="#0057B7" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="poll-title mt-6">
      <input type="text" name="title" id="poll-title" placeholder="Title" class="input w-full"
             v-model="pollTitle">
      <span
          v-for="error of v$.pollTitle.$errors"
          :key="error.$uid"
          class="error"
      >
          {{ error.$message }}
        </span>
    </div>
    <div class="poll-options-wrap mt-6">
      <v-slide-y-transition group>
        <div class="poll-option mb-2" v-for="(option, index) in pollOptions" :key="index">
          <div style="position:relative;">
            <input type="text" :name="`votes[0].options[${index}]`" :id="`votes[0].options[${index}]`"
                   placeholder="Option"
                   class="input w-full" ref="voteOption" v-model="option.value">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                 style="cursor: pointer" class="poll-option-remove" @click="removeOption(option.id)">
              <path d="M18 6C13.7552 9.37699 10.9401 12.5617 6 18" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 6C8.23219 9.25226 10.383 11.6003 18 18" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div v-if="v$.pollOptions.$each.$response">
                    <span
                        v-for="(error, index) in v$.pollOptions.$each.$response.$errors[index].value"
                        :key="index"
                        class="error"
                    >
                      {{ error.$message }}
                    </span>
          </div>
        </div>
      </v-slide-y-transition>

      <div class="poll-add-option input w-full" @click="addOption" v-if="pollOptions.length < 10">
        <span>Add option</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             class="poll-add-option-icon">
          <path d="M12.0003 5C11.6711 16.0034 11.8366 16.2824 12.0003 19" stroke="#0057B7" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5 11.9999C12.3632 11.8692 16.3509 11.8046 19 11.9999" stroke="#0057B7" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <div class="poll-add-option disabled input w-full" v-else>
        <span>Add option</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             class="poll-add-option-icon">
          <path d="M12.0003 5C11.6711 16.0034 11.8366 16.2824 12.0003 19" stroke="#C2C9D1" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5 11.9999C12.3632 11.8692 16.3509 11.8046 19 11.9999" stroke="#C2C9D1" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="separator my-6"></div>
    <div class="poll-settings">
      <div class="settings-title">
        <h2>Settings</h2>
      </div>
      <div class="settings-items mt-6">
        <v-switch
            v-model="pollSettings.multiAnswers"
            :label="`Multi answers`"
        ></v-switch>
        <v-switch
            v-model="pollSettings.anonimePoll"
            :label="`Anonime poll`"
        ></v-switch>
        <v-switch
            v-model="pollSettings.disableCancelReply"
            :label="`Disable cancel reply`"
        ></v-switch>
      </div>
    </div>
    <button class="btn-yellow w-full mt-5" @click.prevent="attachOption">Attach</button>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {helpers, minLength, required} from "@vuelidate/validators";

export default {
  name: "createPollComponent",
  setup() {
    return {v$: useVuelidate({$lazy: true})}
  },
  validations() {
    return {
      pollOptions: {
        $each: helpers.forEach({
          value: {
            required: helpers.withMessage(`Option can't be empty string`, required)
          }
        }),
        minLength: helpers.withMessage(`Should be at least 2 options`, minLength(2)),
      },
      pollTitle: {
        required: helpers.withMessage(`Title is required`, required)
      }
    }
  },
  data() {
    return {
      pollSettings: {
        multiAnswers: false,
        anonimePoll: false,
        disableCancelReply: false
      },
      pollTitle: '',
      pollOptionsId: 0,
      pollOptions: [
        {
          id: 0,
          value: ''
        }
      ],
    }
  },
  methods: {
    async attachOption() {
      let options = this.$refs.voteOption
      const result = await this.v$.$validate()
      let data = {
        pollSettings: this.pollSettings,
        pollTitle: this.pollTitle,
        pollOptions: options,
      }
      if (options.length > 1) {
        if (!result) {
          return
        } else {
          this.$emit('pollCreated', data)
          this.clearPoll()
        }
      }

    },
    clearPoll() {
      this.v$.$reset()
      this.pollOptions = [{id: 0, value: ''}]
      this.pollSettings.anonimePoll = false
      this.pollSettings.multiAnswers = false
      this.pollSettings.disableCancelReply = false
      this.pollTitle = ''
      this.poll = false
    },
    addOption() {
      this.pollOptionsId++
      this.pollOptions.push({id: this.pollOptionsId, value: ''})
    },
    removeOption(id) {
      this.pollOptions = this.pollOptions.filter(i => i.id !== id)
    },
    closePoll() {
      this.clearPoll()
      this.$parent.poll = false
    }
  }
}
</script>

<style scoped>
.poll-header-title, .settings-title {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: var(--primary-blue)
}

.poll-option {
  position: relative;
}

.poll-add-option {
  position: relative;
  cursor: pointer;

}

.poll-add-option-icon, .poll-option-remove {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.poll-add-option.disabled {
  color: #C2C9D1;
}

.poll-name {
  font-weight: 700;
}
</style>