<template>
  <div class="d-flex flex-column">
    <svg class="mb-10" width="86" height="16" viewBox="0 0 86 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-717a1ee8=""><path d="M69.2581 0.727295C68.5121 0.727295 67.8905 1.33006 67.8905 2.05338C67.8905 2.7767 68.5121 3.37947 69.2581 3.37947H75.4119V12.6621C63.6015 12.5415 58.6287 10.7332 56.5774 9.40713H61.7367C64.1609 9.40713 66.15 7.47828 66.15 5.12749C66.15 2.7767 64.1609 0.847851 61.7367 0.847851H47.6263L36.5618 11.7579C36.4996 11.8182 36.3753 11.8785 36.3131 11.8785C36.2509 11.8785 36.1266 11.8785 36.0645 11.7579L25.8702 2.11366C24.627 0.968403 22.7 0.968402 21.519 2.17393L11.7598 11.7579C11.6355 11.8785 11.5733 11.8785 11.449 11.8785C11.3868 11.8785 11.2625 11.8785 11.2003 11.7579L3.18167 1.33006C2.74655 0.727295 1.87631 0.606745 1.25471 1.08896C0.633102 1.51089 0.508782 2.35477 1.00606 2.95753L9.02474 13.3854C9.58418 14.1087 10.3923 14.5306 11.2625 14.5909C12.1328 14.6512 13.0652 14.3498 13.6868 13.6868L23.4459 4.16306C23.5702 4.04251 23.8189 4.04251 23.9432 4.16306L34.1375 13.8073C34.7591 14.3498 35.505 14.7115 36.3753 14.6512C37.1834 14.6512 37.9914 14.2895 38.5509 13.747L48.7452 3.43974H61.6745C62.6069 3.43974 63.415 4.16306 63.415 5.12749C63.415 6.03164 62.6691 6.81524 61.6745 6.81524H52.4126V8.14132C52.4126 9.34685 53.6558 15.3745 76.7795 15.3745H78.147V3.31919H83.9901C84.736 3.31919 85.3576 2.71643 85.3576 1.99311C85.3576 1.26979 84.6738 0.727295 83.9279 0.727295H69.2581Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M23.7571 12.6019C22.7958 12.6019 22.0166 11.8462 22.0166 10.9141C22.0166 9.982 22.7958 9.22638 23.7571 9.22638C24.7183 9.22638 25.4976 9.982 25.4976 10.9141C25.4976 11.8462 24.7183 12.6019 23.7571 12.6019Z" fill="red"></path></svg>
    <img width="100" height="50" src="https://i0.wp.com/www.daorecords.org/wp-content/uploads/2022/11/cropped-7.png?fit=439%2C279&amp;ssl=1" class="mb-10" alt="DAOrecords">
  </div>
</template>

<script>

export default {
  name: "TestTest",
  data() {
    return {
      Options: {
        options: {
          methods: ['card', 'banklinks_eu', 'wallets', 'local_methods'],
          methods_disabled: [],
          card_icons: ['mastercard', 'visa', 'maestro'],
          active_tab: 'card',
          default_country: 'PL',
          countries: ["PL", "IE", "GB", "CZ", "GE"],
          fields: false,
          title: 'my_title',
          link: 'https://shop.com',
          full_screen: true,
          button: true,
          locales: ['cs', 'de', 'en', 'es', 'fr', 'hu', 'it', 'ko', 'lv', 'pl', 'ro', 'ru', 'sk', 'uk'],
          email: true
        },
        params: {
          merchant_id: 1396424,
          required_rectoken: 'y',
          currency: 'EUR',
          amount: 500,
          order_desc: 'my_order_desc',
          response_url: 'http://shop.com/thankyoupage'
        },
        messages: {
          pl: {
            card_number: 'Numer karty',
            my_title: 'Cel płatności',
            my_order_desc: 'Płatność testowa'
          },
          en: {
            card_number: 'Card number',
            my_title: 'Order description',
            my_order_desc: 'Test order'
          }
        }
      }
    }
  },
  methods: {
    check(data) {
      console.log(data)
    }
  },
  components: {}
}
</script>

<style scoped>
.comments ul {
  padding-left: 16px;
  margin: 6px 0;
}
</style>