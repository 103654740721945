import { render, staticRenderFns } from "./TestPopup.vue?vue&type=template&id=37659f35&scoped=true"
import script from "./TestPopup.vue?vue&type=script&lang=js"
export * from "./TestPopup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37659f35",
  null
  
)

export default component.exports