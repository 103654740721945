import { render, staticRenderFns } from "./VideoCongratulationsView.vue?vue&type=template&id=c54bbff8&scoped=true"
import script from "./VideoCongratulationsView.vue?vue&type=script&lang=js"
export * from "./VideoCongratulationsView.vue?vue&type=script&lang=js"
import style0 from "./VideoCongratulationsView.vue?vue&type=style&index=0&id=c54bbff8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c54bbff8",
  null
  
)

export default component.exports