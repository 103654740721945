import { render, staticRenderFns } from "./CreateLegendSongForm.vue?vue&type=template&id=68acd111&scoped=true"
import script from "./CreateLegendSongForm.vue?vue&type=script&lang=js"
export * from "./CreateLegendSongForm.vue?vue&type=script&lang=js"
import style0 from "./CreateLegendSongForm.vue?vue&type=style&index=0&id=68acd111&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68acd111",
  null
  
)

export default component.exports