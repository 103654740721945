import Vue from 'vue'
import Vuex from 'vuex'
import { profile } from "@/store/profile.module";
import {authentication} from "@/store/auth.module";
import {song} from "@/store/song.module";
import {products} from "@/store/product.module";
import {content} from "@/store/content.module";
import {subscribers} from "@/store/subscribers.module";
import {prices} from "@/store/servisPrices.module";
import{video} from "@/store/video.module";
import {payment} from "@/store/payment.module";
import {comments} from "@/store/comments.module";
import {chat} from "@/store/chat.module";
import {error} from "@/store/error.module";
import {registration} from "@/store/registration.module";
import {hallOfFame} from "@/store/hall-of-fame.module";
import {search} from "@/store/search.module";
import { wallet } from './wallet.module';
import { auctions } from './auctions.module';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    profile,
    authentication,
    song,
    products,
    content,
    subscribers,
    prices,
    video,
    payment,
    comments,
    chat,
    error,
    registration,
    hallOfFame,
    search,
    wallet,
    auctions
  }
})
