<template>
  <form class="upload-song-form" enctype='multipart/form-data' @submit.prevent="onSubmitAction">
    <div class="upload-song__drag-and-drop mt-5" v-show="!songFile.length">
      <DragDropFileUpload ref="DragDropSong" @handleInput="handleSongs"
                          :text="uploadSongText" :title="uploadSongTitle"
                          :supportedFile="'audio/mp3, audio/waw'" :inputId="`${identifier}-upload-song`"/>
    </div>
    <div class="upload-song__set-up" v-if="songFile.length">
      <div class="set-up__song-name mt-5">
        <input type="text" name="fileName" id="upload-song-name" class="input w-full" placeholder="Song name"
               @change="setToFormData" v-model="songName">
        <span
            v-for="error of v$.songName.$errors"
            :key="error.$uid"
            class="error"
        >
          {{ error.$message }}
        </span>
      </div>
      <div class="set-up__genre mt-3">
        <v-select
            v-model="genreValue"
            :items="genres"
            outlined
            dense
            label="Genre"
            @change="formData.set('genre', genreValue)"
        ></v-select>
        <span
            v-for="error of v$.genreValue.$errors"
            :key="error.$uid"
            class="error"
        >
          {{ error.$message }}
        </span>
      </div>
      <div class="set-up__file d-flex align-center input mt-3">
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-3">
          <path
              d="M22 8.22917C22 8.22917 22 23.3743 22 24.2331C22 25.0919 5.09794 25.4073 5.09614 24.2331C5.09433 23.0589 4.96956 11.5102 5.007 2.74136C5.01163 1.65798 13.8876 2.08153 15.0176 2.08165M22 8.22917C22 7.4588 20.4476 5.21492 19.0994 4.19496C17.6955 3.13283 16.1476 2.08177 15.0176 2.08165M22 8.22917C21.6468 8.69825 21.4688 9.1875 19.3438 9.42124C17.2188 9.65498 15.0176 10.0021 15.0176 9.42124C15.0176 8.84038 15.024 6.44522 15.0176 6.22653C14.965 4.44042 15.0176 3.86818 15.0176 2.08165"
              stroke="#0057B7" stroke-width="2" stroke-linecap="round"/>
        </svg>
        <span class="mr-3">{{ songFile[0].file.name }}</span>
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"
             class="cancel-song" @click="removeSong">
          <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <p class="mt-3">(.WAV .mp3 up 50mb)</p>
      <div class="set-up__song" v-if="songData">
        <div class="w-full songs-item">
          <button class="play-button" @click.prevent="toggleMusic">
            <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg"
                 v-if="togglePlayerFlag">
              <path
                  d="M5.24489 7.5C9.2977 2.65915 11.936 0.595703 21.468 0.595703C31 0.595703 33.7641 4.10685 37 9.5C40 14.5 39.9352 18.5656 39.688 24.5C39.4778 29.5444 36.7646 35.0416 32.5 38.2565C22.1351 46.0701 3.62313 39.4435 1.00051 26.3304C-0.269249 19.9816 0.945866 12.6349 5.24489 7.5Z"
                  fill="#0057B7"/>
              <path
                  d="M25.9993 20.0007C25.9993 18.5014 17.0026 12.4998 17 13.0002C16.9975 13.5007 17.2247 16.5946 17.2234 20.5C17.223 21.8666 16.9981 25.9992 17 26.4999C17.0019 27.0007 25.9993 21.5 25.9993 20.0007Z"
                  stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
              <path
                  d="M5.24489 7.5C9.2977 2.65915 11.936 0.595703 21.468 0.595703C31 0.595703 33.7641 4.10685 37 9.5C40 14.5 39.9352 18.5656 39.688 24.5C39.4778 29.5444 36.7646 35.0416 32.5 38.2565C22.1351 46.0701 3.62313 39.4435 1.00051 26.3304C-0.269249 19.9816 0.945866 12.6349 5.24489 7.5Z"
                  fill="#0057B7"/>
              <path
                  d="M17 14.0005C16.9975 14.5009 17.2247 17.5949 17.2234 21.5002C17.223 22.8668 16.9981 26.9994 17 27.5002"
                  stroke="white" stroke-width="2" stroke-linecap="round"/>
              <path d="M24 14C23.9975 14.5004 24.2248 17.5947 24.2235 21.5C24.223 22.8666 24.2216 26.9993 24.2235 27.5"
                    stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>


          </button>
          <div class="song-info">
            <p class="song-name">{{ songData.songName }}</p>
            <p class="author-name">{{ songData.author.nickName }}</p>
          </div>
        </div>
      </div>
            <button class="btn-yellow w-full mt-5" @click.prevent="songUploaded" v-if="showAttach">Attach</button>
    </div>
    <div class="preloader-wrap d-flex justify-center align-center" v-if="uploadFlag">
      <PreLoader />
    </div>
  </form>
</template>

<script>
import DragDropFileUpload from "@/components/DragDropFileUpload";
import convertTime from '@/mixins/convert'
import {mapActions, mapGetters, mapMutations} from "vuex";
import {useVuelidate} from '@vuelidate/core'
import {required, helpers} from '@vuelidate/validators'
import PreLoader from "@/components/elements/Preloader";

export default {
  name: "UploadSongComponent",
  mixins: [convertTime],
  setup() {
    return {v$: useVuelidate({$lazy: true})}
  },
  validations() {
    return {
      songName: {
        required: helpers.withMessage(`Song name is required`, required)
      },
      genreValue: {
        required: helpers.withMessage(`Genre is required`, required)
      }
    }
  },
  props: {
    showAttach: {
      type: Boolean,
      default: true
    },
    identifier: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formData: new FormData(),
      uploadSongText: 'Supported file type mp3, waw. Audio file must not exceed 200 MB',
      uploadSongTitle: 'Upload song',
      songName: '',
      songFile: [],
      songDuration: '00:00',
      songDurationInMs: 0,
      genreValue: '',
      genreItems: ['foo', 'bar', 'fizz', 'buzz'],
      songData: null,
      player: new Audio(),
      togglePlayerFlag: true,
      uploadFlag: false
    }
  },
  computed: {
    ...mapGetters({
      genres: 'song/getGenres'
    }),
  },
  methods: {
    ...mapActions({
      getGenresRequest: 'song/getGenresAction',
      uploadSong: 'song/uploadSong',
      getSongsRequest: 'products/getSongsRequest'
    }),
    ...mapMutations({
      setSongToCurrent: 'song/SET_CURRENT'
    }),
    toggleMusic() {
      if (this.togglePlayerFlag) {
        // this.$emit('currentSong', this.song.id)
        this.setSongToCurrent(this.songData.songName)
        this.player.play()
        this.togglePlayerFlag = false
      } else {
        this.player.pause()
        this.togglePlayerFlag = true
      }
    },
    async songUploaded() {
      // let songDuration = await this.getSongDuration(this.songFile[0])
      const result = await this.v$.$validate()

      if (!result) {
        return false
      } else {
        this.$emit('songUploaded')
        this.v$.$reset()
      }
    },
    async onSubmitAction() {
      const result = await this.v$.$validate()
      let user = JSON.parse(localStorage.getItem('user'))
      this.songDurationInMs = Math.round(this.player.duration)
      if (!result) {
        return false
      } else {
        // this.formData.set('genre', this.genreValue)
        this.formData.set('owner-uuid', user.uuid)
        this.formData.set('lengthOfSong', this.songDurationInMs)
        const payload = {
          uuid: user.uuid,
          body: this.formData
        }
        this.uploadFlag = true
        await this.uploadSong(payload)
        this.v$.$reset()
        this.uploadFlag = false
        await this.getSongsRequest(user.uuid)
        this.$emit('closePopup')
        this.removeSong()
      }

    },
    dataForPost() {
      let songDurationInMs = Math.round(this.player.duration)
      return {
        files: this.songFile,
        songName: this.songName,
        genre: this.genreValue,
        lengthOfSong: songDurationInMs
      }
    },
    setToFormData(event) {
      let name = event.target.name
      let value = event.target.value
      this.formData.set(name, value)
    },
    handleSongs(files) {
      // let convert = (files[0].file.size / (1024 * 1024)).toFixed(2);

      this.formData.set('songFile', files[0].file)
      let reader = new FileReader()
      reader.readAsDataURL(files[0].file)
      reader.addEventListener('loadend', () => {
        this.player.src = reader.result
        this.songFile = files
        this.songData = {
          songName: files[0].file.name,
          author: {
            nickName: JSON.parse(localStorage.getItem('user')).nickName
          }
        }
        this.getGenresRequest()
      })

    },
    removeSong() {
      this.formData.delete('songFile')
      this.$refs.DragDropSong.deleteCover()
      this.songFile = []
      this.genreValue = ''
      this.songDuration = '00:00'
      this.songName = ''
      this.$emit('songRemoved')
      this.v$.$reset()
    },
    async validateForm() {
      const result = await this.v$.$validate()
      if(!result) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    songFile(newVal) {
      this.$emit('isSongUploaded', newVal.length)
    }
  },
  mounted() {
  },
  components: {
    PreLoader,
    DragDropFileUpload
  }
}
</script>

<style scoped>
span, p {
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
}

.set-up__file span {
  font-weight: 600;
}

.set-up__make-post span {
  font-weight: 700;
}

.set-up__file {
  position: relative;
}

.cancel-song {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  cursor: pointer;
}

.set-up__song {
  margin-top: 20px;
  padding-left: 15px;
}

.songs-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.play-button {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.song-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
  margin-bottom: 8px;
}

.author-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--primary-blue);
}

@media only screen and (max-width: 600px) {
  .cancel-song {
    right: 15px;
  }
}
</style>