<template>
  <div class="induct-legend-wrapper">
    <div class="d-flex justify-space-between align-center">
      <h2 class="page-title">Initial sale creating</h2>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$router.back()" class="mobile">
        <path d="M18 6C13.7552 9.37699 10.9401 12.5617 6 18" stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 6C8.23219 9.25226 10.383 11.6003 18 18" stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="induct-legend-form-wrapper">
      <CreateAuctionForm ref="CreateAuctionForm"/>
    </div>
    <ButtonBack/>
  </div>
</template>

<script>
import ButtonBack from "@/components/elements/ButtonBack";
import CreateAuctionForm from "@/components/auctions/CreateAuctionForm";

export default {
  name: "AddInitialSale",
  components: {CreateAuctionForm, ButtonBack},
  data() {
    return {
      tab: 'SONG',
    }
  },
  methods: {}
}
</script>

<style scoped>
.induct-legend-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 40px;
}

.induct-legend-tabs {
  margin-top: 40px;
}

.tabs-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}

.tabs-item {
  width: calc(50% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 16px 0;
  cursor: pointer;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
}

@media only screen and (max-width: 600px) {
  .induct-legend-wrapper {
    padding: 20px;
  }
}
</style>