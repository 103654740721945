import { render, staticRenderFns } from "./NavMessagesIcon.vue?vue&type=template&id=5bb18c19&scoped=true"
import script from "./NavMessagesIcon.vue?vue&type=script&lang=js"
export * from "./NavMessagesIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb18c19",
  null
  
)

export default component.exports