import auction_instance from "../api/auction-instance"

// {
//     name: '1 week',
//     value: 604800
// },
export const auctions = {
    namespaced: true,
    state: {
        durations: [
            {
                name: '5 min',
                value: 300
            },
            {
                name: '1 week',
                value: 604800
            },
            {
                name: '2 week',
                value: 1209600
            },
            {
                name: '3 week',
                value: 1814400
            },
            {
                name: '4 week',
                value: 2419200
            },
        ],
    },
    getters: {
        getDurationList(state) {
            return state.durations
        }
    },
    mutations: {

    },
    actions: {
        async getAllAuctions(ctx, {body, params}) {
            try {
                const response = await auction_instance.request({
                    method: 'get',
                    url: '/api/v1/auctions',
                    params: params,
                    data: body,
                    headers: { 'Content-Type': 'application/json' },
                });
                return response.data;
            } catch (error) {
                console.error('Error fetching auctions:', error);
                throw error; 
            }
        },
        async getAuctionsSearch(ctx, {params}) {
            try {
                const response = await auction_instance.request({
                    method: 'get',
                    url: '/api/v1/auctions/search',
                    params: params,
                    headers: { 'Content-Type': 'application/json' },
                });
                return response.data;
            } catch (error) {
                console.error('Error fetching auctions:', error);
                throw error; 
            }
        },
        async getAuction(ctx, uuid) {
            return (await auction_instance.get(`/api/v1/auctions/${uuid}`)).data
        },
        async updateAuction(ctx, uuid) {
            return await auction_instance.patch(`/api/v1/auctions/${uuid}`).data.body
        },
        async updateAuctionBid(ctx, {uuid, body}) {
            try {
                if (!body || typeof body !== 'object') {
                    throw new Error('Invalid request body. Expected a non-empty object.');
                }
        
                const response = await auction_instance.request({
                    method: 'patch',
                    url: `/api/v1/auctions/${uuid}/bid`,
                    data: body, // Explicitly stringify the body
                    headers: { 
                        'Content-Type': 'application/json' 
                    },
                });
        
                return response.data;
            } catch (error) {
                console.error('Error updating auction bid:', error);
        
                // Add more descriptive error handling
                if (error.response) {
                    // Server responded with a status code outside the 2xx range
                    console.error('Response data:', error.response.data);
                    console.error('Response status:', error.response.status);
                    console.error('Response headers:', error.response.headers);
                } else if (error.request) {
                    // Request was made but no response received
                    console.error('Request data:', error.request);
                } else {
                    // Something else happened during the setup of the request
                    console.error('Error message:', error.message);
                }
        
                throw error; 
            }
        },
        async finishAuction(ctx, {uuid}) {
            try {
        
                const response = await auction_instance.request({
                    method: 'patch',
                    url: `/api/v1/auctions/${uuid}/finish`,
                    headers: { 
                        'Content-Type': 'application/json' 
                    },
                });
        
                return response.data;
            } catch (error) {
                console.error('Error finishing auction:', error);
        
                if (error.response) {
                    console.error('Response data:', error.response.data);
                    console.error('Response status:', error.response.status);
                    console.error('Response headers:', error.response.headers);
                } else if (error.request) {
                    console.error('Request data:', error.request);
                } else {
                    // Something else happened during the setup of the request
                    console.error('Error message:', error.message);
                }
        
                throw error; 
            }
        },
        async createAuction(ctx, body) {
            await auction_instance.post(`/api/v1/auctions`, body)
        },
        async deleteAuction(ctx, uuid) {
            return (await auction_instance.delete(`/api/v1/legends/${uuid}`))
        }
    }
}