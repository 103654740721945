
export const wallet = {
    namespaced: true,
    state: {
        provider: null,
        address: null,
        isConnected: false
    },
    getters: {
        getProvider(state) {
            return state.provider
        },
        getAddress(state) {
            return state.address
        },
        getConnection(state) {
            return state.isConnected
        }
    },
    mutations: {
        SET_PROVIDER(state, payload) {
            state.provider = payload
        },
        SET_ADDRESS(state, payload) {
            state.address = payload
        },
        SET_CONNECTION(state, payload) {
            state.isConnected = payload
        }
    },
    actions: {
       async updateProvider(context, payload) {
            context.commit('SET_PROVIDER', payload)
       },
       async updateAddress(context, payload) {
            context.commit('SET_ADDRESS', payload)
       },
       async updateConnection(context, payload) {
            context.commit('SET_CONNECTION', payload)
       }
    }
}