<template>
    <div class="flex justify-center mx-auto w-7 h-7">
        <svg width="20" height="25" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9032 21.9213C8.62932 22.119 7.15612 24.1357 5.19817 25.0059C4.46216 25.333 3.69402 26.303 3.1275 26.8695C2.82018 27.1768 2.4919 27.4337 2.19213 27.7335C1.88934 28.0363 2.03505 27.5728 2.03505 27.2908C2.03505 26.662 2.13538 26.0416 2.2921 25.4343C2.80306 23.4543 3.11716 21.3823 3.47738 19.3651C3.71778 18.0188 3.96291 16.5421 3.96291 15.1738C3.96291 14.6046 4.05378 14.0675 4.0843 13.503C4.1079 13.0663 3.9953 12.5205 4.1557 12.1035C4.45599 11.3227 4.15035 10.1143 4.02717 9.32593C3.90908 8.5701 4.15357 7.38138 3.77013 6.69118C3.68349 6.53524 3.6017 5.88314 3.83439 5.85577C4.15653 5.81787 4.5583 5.49535 4.83402 5.34167C5.51908 4.95984 6.2283 4.59763 6.94753 4.28492C9.31275 3.25656 11.8917 3.23452 14.3734 2.6141C15.0846 2.43629 15.6892 2.38561 16.4298 2.38561C16.6453 2.38561 17.4381 2.26434 17.5865 2.44987C18.1517 3.15638 17.7551 4.36686 17.8507 5.22743C18.3267 9.51142 18.4862 13.9024 18.4862 18.2227C18.4862 21.3644 18.4862 24.5061 18.4862 27.6478C18.4862 28.4947 18.3533 27.9244 18.072 27.5193C17.8326 27.1745 17.6548 26.7988 17.4294 26.4482C17.0794 25.9038 16.3019 25.5501 15.8157 25.1344C14.355 23.8854 12.9444 22.79 11.3174 21.7928C10.9545 21.5704 10.6028 21.5783 10.2606 21.4072" stroke="#0057B7" stroke-width="2" stroke-linecap="round"/>
        </svg>
    </div>
  </template>
  
  <script>
  export default {
    name: "NavAuctionsIcon"
  }
  </script>
  
  <style scoped>
  
  </style>