<template>
  <div>
    <div class="mobile-header mb-8">
      <div class="d-flex align-center justify-space-between w-full">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4" @click="$router.back()">
          <rect width="30" height="30" rx="15" fill="#0057B7"/>
          <path d="M13.413 9L9.5 15M9.5 15L13.413 21M9.5 15L21.5 15" stroke="#FFD60A" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="event-wrapper view-wrapper align-self-start" ref="top">
      <div class="event-wrap d-flex">
        <img loading="lazy" :src="getEvent.eventCoverThumbnail" alt="Ukrmusic" class="event-cover"
             v-if="getEvent && getEvent.eventCoverThumbnail">
        <img src="../assets/img/default-cover.png" alt="Ukrmusic" class="event-cover" v-else>
        <div class="w-full">
          <div class="event-title-wrap d-flex">
            <h2 class="event-title" v-if="getEvent">{{ getEvent.eventTitle }}</h2>
<!--            <div class="d-flex align-center ml-auto">-->
<!--              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-8">-->
<!--                <path-->
<!--                    d="M18.5 8.5C20.1569 8.5 21.5 7.15685 21.5 5.5C21.5 3.84315 19.6569 2.5 18 2.5C16.3431 2.5 15 3.84315 15 5.5C15 7.15685 16.8431 8.5 18.5 8.5Z"-->
<!--                    stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path-->
<!--                    d="M5.50033 15.5C7.15718 15.5 9.00033 14.1569 9.00033 12.5C9.00033 10.8431 7.15718 9.5 5.50033 9.5C3.84347 9.5 3 10.8017 3 12.4585C3 14.1154 3.84347 15.5 5.50033 15.5Z"-->
<!--                    stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path-->
<!--                    d="M17.5 22C19.1569 22 20.5 21.6569 20.5 20C20.5 18.3431 19.6569 16.5 18 16.5C16.3431 16.5 15 17.8431 15 19.5C15 21.1569 15.8431 22 17.5 22Z"-->
<!--                    stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path d="M8.58984 14.0098L15.4198 17.9898" stroke="#0057B7" stroke-width="2" stroke-linecap="round"-->
<!--                      stroke-linejoin="round"/>-->
<!--                <path d="M16 7.5L8.58984 10.9898" stroke="#0057B7" stroke-width="2" stroke-linecap="round"-->
<!--                      stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--              <svg width="28" height="7" viewBox="0 0 28 7" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path-->
<!--                    d="M5.5 3.50098C5.5 5.15783 4.65685 6.50098 3 6.50098C1.34315 6.50098 0 5.15783 0 3.50098C0 1.84412 1.34315 0.500977 3 0.500977C4.65685 0.500977 5.5 1.84412 5.5 3.50098Z"-->
<!--                    fill="#0057B7"/>-->
<!--                <path-->
<!--                    d="M17 3.50098C17 5.15783 15.6569 6.50098 14 6.50098C12.3431 6.50098 10.5 5.15685 10.5 3.5C10.5 1.84315 11.8431 0.5 13.5 0.5C15.1569 0.5 17 1.84412 17 3.50098Z"-->
<!--                    fill="#0057B7"/>-->
<!--                <path-->
<!--                    d="M28.0007 3.50098C28.0007 5.15783 26.6764 6.17781 25.0195 6.17781C23.3627 6.17781 22.0195 4.83466 22.0195 3.17781C22.0195 1.52095 23.3438 0.500977 25.0007 0.500977C26.6575 0.500977 28.0007 1.84412 28.0007 3.50098Z"-->
<!--                    fill="#0057B7"/>-->
<!--              </svg>-->

<!--            </div>-->
          </div>
          <div class="d-flex mt-5 event-date-and-time" v-if="getEvent"><span>{{ splitedDateAndTime[0] }}</span>
            <div class="event-separator"></div>
            <span>{{ splitedDateAndTime[1] }}</span></div>
          <p class="event-description mt-10" v-if="getEvent">{{ getEvent.description }}</p>
          <div class="btn-yellow w-full mt-10" style="cursor: pointer">Buy tickets</div>
        </div>
      </div>
      <div style="margin-top: 60px">
        <h2 class="event-title" v-if="getEvents && getEvents.length">Also in</h2>
        <div class="d-flex flex-wrap">
          <EventItemComponent
              v-for="(event, index) in getEvents"
              :key="index"
              :event="event"
              @scrollToTop="scrollToTop"
          />
        </div>
      </div>
      <v-pagination
          v-if="getEvents && getEvents.length"
          v-model="page"
          :length="count"
          :total-visible="7"
          :color="'#0057B7'"
          prev-icon="<"
          next-icon=">"
          @input="onPagination"
          class="mt-10 mb-5"
      ></v-pagination>
      <ButtonBack/>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import EventItemComponent from "@/components/EventItemComponent";
import user from "@/mixins/user";
import ButtonBack from "@/components/elements/ButtonBack";

export default {
  name: "EventView",
  mixins: [user],
  components: {ButtonBack, EventItemComponent},
  data() {
    return {
      page: 1,
      count: null,
      pageSize: 6,
      eventId: this.$route.params.id,
      isOwner: false
    }
  },
  methods: {
    ...mapActions({
      getEventAction: 'products/getEventRequest',
      getEventList: 'products/getEventListRequest',
    }),
    async getEventRequest() {
      const uuid = this.$route.query.userUuid
      const payload = {
        uuid: uuid,
        eventId: this.$route.params.id
      }
      await this.getEventAction(payload)

    },
    async eventListRequest() {
      const uuid = this.$route.query.userUuid
      const payload = {
        uuid: uuid,
        params: this.getRequestParams(this.page, this.pageSize)
      }
      let res = await this.getEventList(payload)
      this.count = res.totalPages
    },
    onPagination() {
      this.$router.push(this.linkGen(this.page)).catch(() => {
      })
    },
    linkGen(pageNum) {
      return {
        path: `/profile/${this.$route.params.nickname}/events/${this.$route.params.id}`,
        query: {page: pageNum, userUuid: this.$route.query.userUuid}
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    scrollToTop() {
      this.$refs.top.scrollIntoView({behavior: "smooth"})
    }
  },
  computed: {
    ...mapGetters({
      getEvent: "products/getEvent",
      getEvents: 'products/getEvents'
    }),
    splitedDateAndTime() {
      return this.getEvent.eventStartAt.split(' ')
    }
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = +newVal
          this.getEventRequest()
          this.eventListRequest()
          if (this.count < newVal) {
            this.page = 1
          }
        } else {
          this.page = 1
        }
        this.scrollToTop()
      }
    }
  },
  created() {
    this.getEventRequest()
    this.eventListRequest()
  },
  mounted() {
    let isLogged = JSON.parse(localStorage.getItem('isLogged'))
    if (isLogged) {
      this.isOwner = this.isOwnerHandler()
    } else {
      this.isOwner = false
    }
  }
}
</script>

<style scoped>

.event-cover {
  max-width: 235px;
  width: 100%;
  height: 190px;
  border-radius: 10px;
  margin-right: 60px;
}

.event-title {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: var(--primary-blue);
}

.event-separator {
  height: 18px;
  width: 2px;
  background-color: var(--primary-yellow);
  margin: 0 8px;
}

.event-date-and-time span {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--primary-blue);
}

.event-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

@media only screen and (max-width: 600px) {
  .event-wrap {
    flex-direction: column;
  }

  .event-cover {
    max-width: unset;
  }

  .event-title-wrap {
    margin-top: 30px;
  }
}
</style>