<template>
  <div class="card-wrapper">
    <img :src="item.coverUrl" loading="lazy" alt="Ukrmusic" v-if="item && item.coverUrl"
         @click="$router.push({path: `/auctions/${item.auctionId}`})">
    <img src="../../assets/img/default-cover.png" alt="Ukrmusic" loading="lazy" v-else
         @click="$router.push({path: `/auctions/${item.auctionId}`})">
    <div class="name-wrapper d-flex align-center mt-4">
      <HallOfFameAudio :fileUrl="item.songUrl" :ref="`audio-player-${identifier}-${index}`" :uuid="toString(item.auctionId)"/>
      <div :style="{marginLeft: 10 + 'px'}">
        <div class="name">{{ item.songName }}</div>
        <div class="mt-3 description" v-if="getCase === 'case1' || getCase === 'case2'">{{ item.authorName }}</div>
        <div class="mt-3 description" v-else>{{ eraHandler(item.era) }}</div>
      </div>
    </div>
    <div>
      <button class="btn-yellow w-full mt-5"
              @click="$router.push({path: `/auctions/${item.auctionId}`})">
        View
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import HallOfFameAudio from "@/components/hall-of-fame/HallOfFameAudio";

export default {
  name: "AuctionsCardComponent",
  components: {
    HallOfFameAudio

  },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    getCase: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    identifier: {
      type: String,
      required: true
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      current: 'song/getCurrent',
      isLogged: 'authentication/isLogged',
      erasList: 'hallOfFame/getEraList'
    }),
  },
  methods: {
    eraHandler(era) {
      let result;
      this.erasList.forEach(i => {
        if(i.value === era) {
          result = i.name
        }
      })
      return result
    },
  },
  watch: {
    current(newVal) {
      if (newVal !== this.item.auctionId) {
        this.$refs[`audio-player-${this.identifier}-${this.index}`].refreshSong()
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.card-wrapper {
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  padding: 20px;
}

img {
  width: 100%;
  height: 234px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}

.name {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: var(--primary-blue);
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--primary-blue);
}

.vote-progress {
  width: 100%;
  height: 8px;
  background: #FFD700;
  border-radius: 10px;
}

.vote-progress__filled {
  height: 100%;
  background: #0057B7;
  border-radius: 10px;
}

.vote-progress__vp {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
  margin-top: 8px;
}

@media only screen and (max-width: 600px) {
  .name {
    max-width: unset;
    overflow: unset;
  }
}

</style>