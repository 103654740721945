<template>
  <div>
    <div class="mobile-header mb-10">
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
           @click="$router.back()">
        <rect width="30" height="30" rx="15" fill="#0057B7"/>
        <path d="M13.413 9L9.5 15M9.5 15L13.413 21M9.5 15L21.5 15" stroke="#FFD60A" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  <div class="legend-wrapper" v-if="auction" ref="top">
    <ButtonBack/>
    <div class="legend-header">
      <div class="header-cover">
        <img loading="lazy" :src="auction.coverUrl" alt="Ukrmusic" v-if="auction && auction.coverUrl"
             class="legend-cover">
        <img src="../../assets/img/default-cover.png" alt="Ukrmusic" class="legend-cover" v-else>
      </div>
      <div class="header-content">
        <HallOfFameAudio :fileUrl="auction.songUrl" :ref="`audio-player-${identifier}-${index}`"
                         :uuid="auction.authorUuid" v-if="auction" class="mb-2 mb-sm-4"/>
        <h2 class="legend-name">{{ auction.songName }}</h2>
        <h4 class="legend-author-name">{{ auction.authorName }}</h4>
        <div class="socials desktop" v-if="auction.socialMedia">
          <div class="social-item" v-if="auction.socialMedia.spotifyUrl">
            <img :src="require('../../assets/svg/icons/spotify.svg')" alt="Ukrmusic" @click="redirectTo(auction.socialMedia.spotifyUrl)">
          </div>
          <div class="social-item" v-if="auction.socialMedia.twitterUrl">
            <img :src="require(`../../assets/svg/icons/youtube.svg`)" alt="Ukrmusic" @click="redirectTo(auction.socialMedia.twitterUrl)">
          </div>
        </div>
      </div>
      <!-- <div class="header-winner-cup" v-if="legend.status === 'SUCCESS'">
        <img src="../../assets/img/hall-of-fame-winner-cup.png" alt="Ukrmusic">
      </div> -->
    </div>
    <div class="socials mobile" v-if="auction.socialMedia">
        <div class="social-item" v-if="auction.socialMedia.spotifyUrl">
          <img :src="require('../../assets/svg/icons/spotify.svg')" alt="Ukrmusic" @click="redirectTo(auction.socialMedia.spotifyUrl)">
        </div>
        <div class="social-item" v-if="auction.socialMedia.twitterUrl">
          <img :src="require(`../../assets/svg/icons/youtube.svg`)" alt="Ukrmusic" @click="redirectTo(auction.socialMedia.twitterUrl)">
        </div>
    </div>
    <div class="separator my-10"></div>
    <div class="legend-body">
      <!-- <div class="separator mt-10 mb-6" v-if="legend && legend.status !== 'SUCCESS'"></div> -->
      <div class="body-user">
        <!-- <div class="user-info" v-if="legend">
          <img loading="lazy" :src="legend.legendStarterUserOwner.avatar" alt="Ukrmusic" v-if="legend.legendStarterUserOwner.avatar"
               class="legend-avatar">
          <img src="../../assets/img/default-avatar.png" alt="Ukrmusic" class="legend-avatar" v-else>
          <div class="legend-info">
            <h3 class="legend-user-name">{{ auction.authorName }}</h3>
          </div>
         <div class="legend-user-date">{{ legend.createdAt.split(' ')[0] }}</div>
        </div> -->
        <div class="user-description">{{ auction.description }}</div>
        <div class="user-link" v-if="auction.customLink">
          <a :href="auction.customLink" @click.prevent="redirectTo(auction.customLink)">{{ auction.customLink }}</a>
        </div>
      </div>
      <div class="separator my-5"></div>
      <div class="body-info">
        <div class="info-item" v-if="auction.era">
          <div class="item-title">Era:</div>
          <div class="item-text">{{ eraHandler(auction.era) }}</div>
        </div>
        <div class="info-item" v-if="auction.genreType">
          <div class="item-title">Genre:</div>
          <div class="item-text">{{ genreHandler(auction.genreType) }}</div>
        </div>
        <div class="info-item" v-if="auction.textAuthor">
          <div class="item-title">Text author:</div>
          <div class="item-text">{{ auction.textAuthor }}</div>
        </div>
        <div class="info-item" v-if="auction.songLabel">
          <div class="item-title">Label:</div>
          <div class="item-text">{{ auction.songLabel }}</div>
        </div>
        <div class="info-item" v-if="auction.recordingStudio">
          <div class="item-title">Recording studio:</div>
          <div class="item-text">{{ auction.recordingStudio }}</div>
        </div>
        <div class="info-item" v-if="auction.composer">
          <div class="item-title">Composer:</div>
          <div class="item-text">{{ auction.composer }}</div>
        </div>
      </div>
      <div class="separator my-5"></div>
      <div class="body-info">
        <div class="info-item" v-if="auction.duration">
          <div class="item-title">End of auction in:</div>
          <div class="item-text">{{ auction.duration.split('T')[0] }}</div>
        </div>
        <div class="info-item" v-if="auction.copyrightPercent">
          <div class="item-title">Parts owned:</div>
          <div class="item-text">{{ auction.copyrightPercent }}%</div>
        </div>
        <div class="info-item" v-if="auction.initialPrice">
          <div class="item-title">Initial price:</div>
          <div class="item-text">{{ auction.initialPrice }} Ͼ</div>
        </div>
        <div class="info-item" v-if="auction.highestBid">
          <div class="item-title">Highest Bid:</div>
          <div class="item-text">{{ auction.highestBid }} Ͼ</div>
        </div>
        <div class="info-item" v-if="auction.highestBidder">
          <div class="item-title">Highest Bidder:</div>
          <div class="item-text">{{ auction.highestBidder }}</div>
        </div>
      </div>
    </div>
    <h4 class="input-title mb-4" v-if="isEnded === false &&  isLogged">Your Bid:</h4>
      <input type="number" name="name" id="min-price" class="input w-full" placeholder="0" v-model="bidAmount" :style="{borderColor: v$.bidAmount.$error ? 'red' : '#C2C9D1'}" @change="setData" v-if="isEnded === false && isLogged">
      <span class="error" v-for="error of v$.bidAmount.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
    <div class="footer-buttons" v-if="isEnded === false && isLogged">
      <button class="btn-yellow w-full mt-5"
              @click="makeBid">
              <PreLoader :preloadWidth="2" :preloaderSize="20" :colorOnlyBlue="true" v-if="onLoading"/>
        <span v-else>Make a bid</span>
      </button>
    </div>
    <div class="footer-buttons" v-if="isEnded === true && isLogged && isFinalized === false">
      <button class="btn-yellow w-full mt-5"
              @click="finalize">
              <PreLoader :preloadWidth="2" :preloaderSize="20" :colorOnlyBlue="true" v-if="onLoading"/>
        <span v-else>Finalize</span>
      </button>
    </div>
    <div class="footer-buttons" v-if="isEnded === true && isFinalized === true">
      <button class="btn-yellow disabled btn-no-hover w-full mt-5" disabled>
        <span>Auction has been ended!</span>
      </button>
    </div>
    <div class="loader" v-if="onLoading"></div>
  </div>
  <BidResultPopup ref="BidResultPopup"/>
  </div>
</template>

<script>
import ButtonBack from "@/components/elements/ButtonBack";
import BidResultPopup from "../popups/BidResultPopup.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import HallOfFameAudio from "@/components/hall-of-fame/HallOfFameAudio";
import PreLoader from "@/components/elements/Preloader";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required, minValue} from "@vuelidate/validators";
import { detectConcordiumProvider, SchemaType } from '@concordium/browser-wallet-api-helpers';
import { AccountTransactionType, CcdAmount, ModuleReference } from "@concordium/web-sdk";
import { ConcordiumGRPCClient } from '@concordium/web-sdk/grpc';
import { toBuffer } from "@concordium/web-sdk";
import { TransactionHash } from "@concordium/web-sdk";

export default {
  name: "AuctionsItemPage",
  components: {BidResultPopup, PreLoader, HallOfFameAudio, ButtonBack},
  setup() {
    return {v$: useVuelidate({$lazy: true, $autoDirty: false})}
  },
  data() {
    return {
      auction: null,
      isEnded: false,
      isFinalized: false,
      onLoading: false,
      identifier: 'legend',
      index: 0,
      socials: [],
      customLink: null,
      bidAmount: null
    }
  },
  validations() {
    const messageForRequiredFields = 'This field is required'
    const messageForMinimumValueFields = 'Minimum value - 1'

    return {
      bidAmount: {
          required: helpers.withMessage(messageForRequiredFields, required),
          minValue: helpers.withMessage(`${messageForMinimumValueFields}`, minValue(1))
        },
    }
  },
  computed: {
    ...mapGetters({
      current: 'song/getCurrent',
      isLogged: 'authentication/isLogged',
      genresList: 'song/getGenresList',
      erasList: 'hallOfFame/getEraList'
    }),
    getType() {
      return this.$route.query.type
    },
    calcDaysRemain() {
      // const oneDay = 24 * 60 * 60 * 1000;
      let date1 = new Date()
      let date2 = new Date(this.legend.voteInfo.firstVoteStageOpenUntil)
      let difference = date2.getTime() - date1.getTime()
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return totalDays > 0 ? `${totalDays} days left` : 'On Reviewing'
    },
  },
  methods: {
    ...mapActions({
      getAuction: 'auctions/getAuction',
      updateAuctionBid: 'auctions/updateAuctionBid',
      finishAuction: 'auctions/finishAuction',
      invokeErrorPopup: 'error/invokeErrorPopup'
    }),
    ...mapMutations({
      openSignInPopup: 'authentication/openSignInPopup'
    }),
    checkValidUrl(url) {
      const isStartWithHttp = url.startsWith('http://')
      const isStartWithHttps = url.startsWith('https://')
      return isStartWithHttp || isStartWithHttps
    },
    eraHandler(era) {
      let result;
      this.erasList.forEach(i => {
        if(i.value === era) {
          result = i.name
        }
      })
      return result
    },
    genreHandler(genre) {
      let result;
      this.genresList.forEach(i => {
        if(i.value === genre) {
          result = i.name
        }
      })
      return result
    },
    setData(event) {
      const value = event.target.value

      if (value) {
       this.bidAmount = value
      } else {
        this.bidAmount = 0
      }
    },
    async getAuctionRequest() {
      this.auction = await this.getAuction(this.$route.params.uuid)
      
      let auctionEndUTC = Date.parse(this.auction.duration + "Z");
      let nowUTC = Date.now(); 

      this.isEnded = nowUTC >= auctionEndUTC;
      if (this.auction.auctionStatus == 'FINISHED') {
        this.isFinalized = true
      }
    },
    async makeBid() {
      const isInputCorrect = await this.v$.$validate()
      if (!isInputCorrect) {
        return false
      } else {
        this.onLoading = true
        
        const provider = await detectConcordiumProvider(100)
        .catch((err) => {
          this.invokeErrorPopup("You don't have Concordium Wallet. Please create it.")
          console.log("No wallet:" + err);
          this.onLoading = false
          throw new Error("Create a Concordium wallet!");
        })

        const chain = await provider.getSelectedChain()
          if (chain != '4221332d34e1694168c2a0c0b3fd0f273809612cb13d000d5c2e00e85f50f796') {
            this.invokeErrorPopup("Change your network to Concordium Mainnet!");
            this.onLoading = false
            throw new Error("Change your network to Concordium Mainnet!");
          }

        let address = await provider.getMostRecentlySelectedAccount()
        
        if (!address) {
          await provider.requestAccounts()
          .then((accounts) => {
            address = accounts[0]
          })
          .catch((err) => {
            console.log(err);
            this.onLoading = false
            this.invokeErrorPopup("You rejected the request")
            throw new Error("You rejected the request");
          })
        }

        const grpcClient = new ConcordiumGRPCClient(provider.grpcTransport);

        const moduleRef = ModuleReference.fromHexString(
                '41322156e51d8a1eb542432e6ad5041cc8b0cf3ebf63e5d64e784b95e7b77635'
              );

        const schema = await grpcClient.getEmbeddedSchema(moduleRef)
        const schemaBase64 = this.arrayBufferToBase64(schema.buffer)

        let params = {
                "auction_id": parseInt(this.$route.params.uuid)
              }
        try {
            const txHash = await provider.sendTransaction(address,  AccountTransactionType.Update, 
                    {
                        amount: CcdAmount.fromCcd(this.bidAmount),
                        contractAddress: {
                            index: 10460,
                            subindex: 0,
                        },
                        receiveName: 'auction.bid',
                        maxContractExecutionEnergy: 30000n,
                    },
                    params,
                    {
                        type: SchemaType.Module,
                        value: schemaBase64,
                    },
                    1
                ).catch((error) => {
                  console.log(error);
                  this.invokeErrorPopup("You rejected the request")
                  throw new Error("You rejected the request")
                })
                
          const txHashByteArr = toBuffer(txHash, 'hex')
          const tx = TransactionHash.fromBuffer(txHashByteArr)    
            
          const updateStatus = await grpcClient.waitForTransactionFinalization(tx).catch((error) => {console.log(error)});
          
          if (updateStatus.summary.rejectReason) {
            if (updateStatus.summary.rejectReason.rejectReason == -2) {
              this.invokeErrorPopup("Bid below current highest bid")
              throw new Error("Bid below current highest bid");
            }
            if (updateStatus.summary.rejectReason.rejectReason == -4) {
              this.invokeErrorPopup("Bid too late")
              throw new Error("Bid too late");
            }
            if (updateStatus.summary.rejectReason.rejectReason == -10) {
              this.invokeErrorPopup("Owner cannot participate in the auction")
              throw new Error("Owner cannot participate in the auction");
            }
          }
                   
          let tagValue0 = updateStatus.summary.events[0].tag;   
          let tagValue = updateStatus.summary.events[3]?.tag;
          if (tagValue === 'Updated' || tagValue0 === 'Updated') {
            let payload = {
              uuid: this.$route.params.uuid,
              body: this.getRequestBody(this.bidAmount, address)
            }

            const res = await this.updateAuctionBid(payload)
            
            if (res) {
              this.$refs.BidResultPopup.openPopup('Success!')
              this.auction = res
              this.onLoading = false
            }
          } else {
            this.$refs.BidResultPopup.openPopup('Something went wrong!')
            this.onLoading = false
          }             
        } catch (error) {
          console.log(error);
          this.onLoading = false
        }   
     }
    },
    async finalize() {
        this.onLoading = true
        
        const provider = await detectConcordiumProvider(100)
        .catch((err) => {
          this.invokeErrorPopup("You don't have Concordium Wallet. Please create it.")
          console.log("No wallet:" + err);
          this.onLoading = false
          throw new Error("Create a Concordium wallet!");
        })

        const chain = await provider.getSelectedChain()
          if (chain != '4221332d34e1694168c2a0c0b3fd0f273809612cb13d000d5c2e00e85f50f796') {
            this.invokeErrorPopup("Change your network to Concordium Mainnet!");
            this.onLoading = false
            throw new Error("Change your network to Concordium Mainnet!");
          }

        let address = await provider.getMostRecentlySelectedAccount()
        
        if (!address) {
          await provider.requestAccounts()
          .then((accounts) => {
            address = accounts[0]
          })
          .catch((err) => {
            console.log(err);
            this.onLoading = false
            this.invokeErrorPopup("You rejected the request")
            throw new Error("You rejected the request");
          })
        }

        const grpcClient = new ConcordiumGRPCClient(provider.grpcTransport);

        const moduleRef = ModuleReference.fromHexString(
                '41322156e51d8a1eb542432e6ad5041cc8b0cf3ebf63e5d64e784b95e7b77635'
              );

        const schema = await grpcClient.getEmbeddedSchema(moduleRef)
        const schemaBase64 = this.arrayBufferToBase64(schema.buffer)

        let params = {
                "auction_id": parseInt(this.$route.params.uuid)
              }
        try {
            const txHash = await provider.sendTransaction(address,  AccountTransactionType.Update, 
                    {
                        amount: CcdAmount.zero(),
                        contractAddress: {
                            index: 10460,
                            subindex: 0,
                        },
                        receiveName: 'auction.finalize',
                        maxContractExecutionEnergy: 30000n,
                    },
                    params,
                    {
                        type: SchemaType.Module,
                        value: schemaBase64,
                    },
                    1
                ).catch((error) => {
                  console.log(error);
                  this.invokeErrorPopup("You rejected the request")
                  throw new Error("You rejected the request")
                })
                
          const txHashByteArr = toBuffer(txHash, 'hex')
          const tx = TransactionHash.fromBuffer(txHashByteArr)    
            
          const updateStatus = await grpcClient.waitForTransactionFinalization(tx)
          
          if (updateStatus.summary.rejectReason) {
            if (updateStatus.summary.rejectReason.rejectReason == -5) {
              this.invokeErrorPopup("Auction already finalized")
              throw new Error("Auction already finalized");
            }
            if (updateStatus.summary.rejectReason.rejectReason == -8) {
              this.invokeErrorPopup("Auction still active")
              throw new Error("Auction still active");
            }
          }
          
          let tagValue0 = updateStatus.summary.events[0].tag;
          let tagValue1 = updateStatus.summary.events[3]?.tag;   
          let tagValue = updateStatus.summary.events[6]?.tag;
          if (tagValue === 'Updated' || tagValue0 === 'Updated' || tagValue1 === 'Updated') {
            this.$refs.BidResultPopup.openPopup('Success!')
            this.onLoading = false
            let payload = {
              uuid: this.$route.params.uuid,
            }

            const res = await this.finishAuction(payload)
            
            if (res) {
              this.$refs.BidResultPopup.openPopup('Success!')
              this.auction = res
              this.onLoading = false
            }
          } else {
            this.$refs.BidResultPopup.openPopup('Something went wrong!')
            this.onLoading = false
          }             
        } catch (error) {
          console.log(error);
          this.onLoading = false
        }     
    },
    getRequestBody(highestBid, highestBidder) {
      let body = {};
    
      body['highestBid'] = highestBid
      body['highestBidder'] = highestBidder

      return body;
    },
    arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    },
    redirectTo(link) {
      if(this.checkValidUrl(link)) {
        window.open(link)
      } else {
        window.open(`https://${link}`)
      }
    },
  },
  watch: {
    current(newVal) {
      if (newVal !== this.auction.authorUuid) {
        this.$refs[`audio-player-${this.identifier}-${this.index}`].refreshSong()
      }
    }
  },
  created() {
    this.getAuctionRequest()
  },
  mounted() {
    setTimeout(() => {
      this.$refs.top.scrollIntoView({behavior: "smooth"})
    }, 200)
  }
}
</script>

<style scoped>
.desktop {
  display: flex;
}

.mobile {
  display: none;
}

.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  z-index: 1000;
  background: black;
  opacity: 0.1;
}

.legend-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 40px;
}

.legend-header {
  display: flex;
}

.legend-cover {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  margin-right: 24px;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.legend-name {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  color: #0057B7;
  margin-bottom: 16px;
}

.legend-author-name {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #0057B7;
  margin-bottom: 16px;
}

.socials {
  align-items: center;
}

.social-item {
  margin-right: 20px;
  cursor: pointer;
}

.body-vote {
  display: flex;
}

.body-vote .left {
  flex: 1;
  margin-right: 40px;
}

.vote-progress {
  width: 100%;
  height: 16px;
  border-radius: 10px;
  background: #FFD700;
}

.vote-progress-filled {
  height: 100%;
  width: 30%;
  background: #0057B7;
  border-radius: 10px;
}

.vote-amount {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.vote-amount-current {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: #0057B7;
  margin-right: 8px;
}

.vote-amount-from {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0057B7;
}

.vote-time-left {
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
}

.vote-btn {
  padding: 13px 91px;
}

.user-info {
  display: flex;
  align-items: center;
}

.error {
  position: relative;
  top: 5px;
}

.legend-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
}

.legend-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.legend-user-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #0057B7;
}

.legend-user-date {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
  margin-left: auto;
}

.user-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
  color: #000000;
  word-break: break-all;
}

.user-link {

  margin-top: 20px;
}

.user-link a {
  text-decoration-line: underline;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #0057B7;
}

.legend-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.legend-footer .left {
  display: flex;
}

.footer-buttons {
  display: flex;
  gap: 20px;
}

.btn-blue {
  border-radius: 10px;
}

.likes, .comments {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0057B7;
  cursor: pointer;
}

.likes svg, .comments svg {
  margin-right: 12px;
}

.likes {
  margin-right: 50px;
}

.show-comments {
  background: #006EE9;
  border-radius: 10px;
}

.show-comments p {
  color: #FFFFFF;
}

.body-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.info-item {
  margin-bottom: 20px;
}

.item-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
  margin-bottom: 8px;
}

.item-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.item-text.judges {
  color: #0057B7;
  cursor: pointer;
}

.header-winner-cup {
  margin-left: auto;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
    margin-top: 20px;
  }

  .body-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .legend-wrapper {
    padding: 20px;
  }

  .body-vote {
    flex-direction: column;
  }

  .body-vote .left {
    margin-right: 0;
  }

  .body-vote .right {
    margin-top: 35px;
  }

  .body-vote .right button{
    width: 100%;
  }

  .legend-cover {
    width: 128px;
    height: 128px;
  }

  .legend-name {
    font-size: 20px;
    line-height: 23px;
  }

  .legend-author-name {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
  }

  .header-winner-cup img {
    width: 40px;
    height: 45px;
  }

}


</style>