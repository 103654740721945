<template>
  <div class="terms-and-conditions-wrapper">
    <h2 class="page-title">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ UKRMUSIC</h2>
    <div class="separator mt-5"></div>
    <h3 class="mt-10">Зміст:</h3>
    <ul class="page-content">
      <li class="mt-5" @click="scrollTo(1)"><span class="list-number">1.</span>Загальні Положення</li>
      <li @click="scrollTo(2)"><span class="list-number">2.</span>Опис Платформи</li>
      <li @click="scrollTo(3)"><span class="list-number">3.</span>Інтелектуальна власність</li>
      <li @click="scrollTo(4)"><span class="list-number">4.</span>Конфіденційність</li>
      <li @click="scrollTo(5)"><span class="list-number">5.</span>Відповідальність</li>
      <li @click="scrollTo(6)"><span class="list-number">6.</span>Зміна Умов</li>
      <li @click="scrollTo(7)"><span class="list-number">7.</span>Заборонені види використання Платформою</li>
      <li @click="scrollTo(8)"><span class="list-number">8.</span>Вилучення або видалення аккаунту з Платформи</li>
      <li @click="scrollTo(9)"><span class="list-number">9.</span>Інші умови</li>
      <li @click="scrollTo(10)"><span class="list-number">10.</span>Зворотній зв'язок</li>
    </ul>
    <div class="separator mt-10"></div>
    <h3 class="mt-10" ref="1">1. Загальні Положення</h3>
    <div class="mt-6">
      <p class="mb-5">
        Ці Умови та правила використання та її Додатки (надалі разом – «Умови») визначають порядок, умови та правила
        користування Вами (надалі Ви/Користувач/Клієнт/Артист) онлайн-платформою Ukrmusic.
      </p>
      <p class="mb-5">
        Товариство з обмеженою відповідальністю «ДЖУКІ» («Джукі/Компанія») створило онлайн-платформу для музичних
        артистів та їх фанів для подальшої взаємодії між ними (надалі – «Платформа/Сайт»). Доступ до Платформи
        здійснюється через веб-сайт <a href="https://ukrmusic.com" target="_blank" class="link">www.ukrmusic.com</a>
      </p>
      <p class="mb-5">
        Будь ласка, прочитайте дуже уважно ці Умови разом з її Додатками перед тим, як прийняти ці положення.
      </p>
      <p class="mb-5">
        Здійснивши реєстрацію на Платформі шляхом надання необхідної для реєстрації інформації, у тому числі
        персональної, та вираження своєї згоди з умовами та положеннями запропонованих до Вашого обов'язкового
        ознайомлення документів, зазначених далі в реченні, підтверджуючи її встановленням позначки «галочка» та
        натискання кнопки «Зареєструватися», Ви підтверджуєте, що Ви прочитали ці Умови, її Додатки, а саме Правила
        користування та Політику Конфіденційності та прийняли їх положення, здійснюючи такі дії.
      </p>
      <p>
        Якщо Ви у будь-який момент не погоджуєтесь з цими Умовами, в такому випадку Ви не маєте права користуватися
        Платформою та негайно маєте залишити Платформу та видалити свій Акаунт (як визначено нижче).
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="2">2. Опис Платформи</h3>
    <div class="mt-6">
      <p class="mt-5">
        Платформа є багатофункціональною соціальною мережою для популяризації українських артистів (далі - Артист) та
        забезпечення їх максимальним інструментарієм для реалізації своєї діяльності у мережі.
      </p>
      <p class="mt-5">
        Передбачається два ключові типи користувачів - артисти та фанати. У фанатів є безкоштовний доступ до звичайного
        контенту,але Артист може встановити ціну за преміум-підписку на свій закритий контент, а також вартість
        відео-вітання від нього.
      </p>
      <p class="mt-5">
        Фан може вдіправити кошти у вигляді «донату» будь-якому Артисту, що є фінансовою підтримкою такого Артиста або
        вираженням вдячності за контент Артиста.
      </p>
      <p class="mt-5">
        Артист може бачити свій баланс від донату,відео-звернень та преміум підписки та створити запит на виведення
        коштів.
      </p>
      <p class="mt-5">
        Валюта Платформи – гривня.
      </p>
      <p class="mt-5">
        Фанат може оформити передплату на одного або кількох артистів, залишити донат (пожертву) будь-якому Артистові
        від 1 гривні до 10 000 гривень, без передплати на нього, або оформити заявку на відео-вітання.
      </p>
      <p class="mt-5">
        У разі оформлення заявки на відео-вітання на рахунку Користувача має бути заморожена певна сума грошей, рівна
        тій, що встановив Артист за дану послугу. Гроші списуються з картки Користувача і зараховуються Артистові, коли
        він відправляє відео-вітання.
      </p>
      <p class="mt-5">
        Фанат може подати заявку на відшкодування коштів, якщо Артист відправив не той контент Користувачу, який був
        описаний у заявці Користувача.
      </p>
    </div>
    <div class="separator mt-4"></div>
    <h3 class="mt-10" ref="3">3. Інтелектуальна власність</h3>
    <div class="mt-6">
      <p class="mb-5">
        Всі об'єкти, розміщені на Сайті, програмний код, торгові марки, а також елементи дизайну, текст, графічні
        зображення, ілюстрації, відео, скрипти, програми, музика, звуки та інші об'єкти і їх добірки, є об'єктами
        виключних прав Джукі і інших правовласників, всі права на ці об'єкти захищені. Кожен з вказаних об'єктів не може
        бути скопійований (відтворений), перероблений, розповсюджений, відображений у фреймі, опублікований, скачаний,
        переданий, проданий або іншим способом використаний цілком або частково без попереднього дозволу правовласника.
      </p>
      <p class="mb-5">
        Ніщо в даній Угоді не може бути розглянуте як передача виключних прав або прав на використання на вказані
        об’єкти інтелектуальної власності.
      </p>
      <p class="mb-5">
        Забороняється будь-яке копіювання інформації з Сайту без попередньої домовленості з Джукі, крім тієї інформації,
        що безпосередньо надсилається Користувачу при наданні Послуг.
      </p>
      <p class="mb-5">
        Копіювання і розміщення на інших ресурсах будь-якої інформації з Сайту має супроводжуватися посиланням на
        джерело та не містити образливої інформації або такої, що може ввести в оману третіх осіб.
      </p>
      <p class="mb-5">
        Сайт може містити лінки/посилання на Веб-сайти третіх сторін, які не підтримуються Джукі. Крім того, інші
        Веб-сайти також можуть містити посилання на Сайт.
      </p>
      <p class="mb-5">
        Джукі не несе відповідальності за зміст цих Веб-сайтів, як не відповідає і за саму наявність таких веб-сайтів чи
        за будь-які втрати, збитки чи шкоду, що виникають у зв'язку з утриманням цих Веб-сайтів.
      </p>
      <p class="mb-5">
        Посилання на інші веб-сайти призначені виключно для зручності користувачів, і не мають на увазі схвалення Сайтом
        даних таких Веб-сайтів або асоціації з їх діяльністю.
      </p>
      <p>
        Сайт не несе ніяких обов'язків і відповідальності за використання інших Веб-сайтів, які Користувач відвідує і
        використовує на власний ризик.
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="4">4. Конфіденційність</h3>
    <div class="mt-6">
      <p class="mb-5">
        При реєстрації на Сайті та користуванні Платформою Користувач надає інформацію щодо себе, яку Джукі вправі
        використовувати для виконання своїх зобов'язань перед Користувачем. Відповідальність за точність та
        достовірність представлених даних несе Користувач.
      </p>
      <p class="mb-5">
        Розміщуючи на Сайті свої персональні дані, Користувач підтверджує, що робить це добровільно, а також що він
        добровільно надає їх Джукі для обробки. У разі якщо Користувач не згоден з вищевказаними умовами, він не повинен
        реєструватися на Сайті або повинен негайно видалити свій акаунт і використовувати Сайт виключно для перегляду.
      </p>
      <p class="mb-5">
        Надаючи свої персональні дані, Користувач дає Джукі свою добровільну згоду на обробку і використання своїх
        персональних даних згідно зі статтями 2, 11 Закону України від 01.06.2010 № 2297-VI «Про захист персональних
        даних».
      </p>
      <p class="mb-5">
        Якщо Користувач не бажає, щоб його персональні дані оброблялися, то він повинен письмово звернутися до Джукі
        (Служби підтримки) із заявою, написаній у довільній формі. В такому випадку вся отримана від Користувача
        інформація видаляється з бази даних Джукі, що унеможливлює подальше надання сервісів Платформи Користувачу.
      </p>
      <p class="mb-5">
        Джукі обробляє тільки ті персональні дані Користувача, які були розміщені ним на Сайті. Персональні дані
        Користувача обробляються за допомогою програмно-апаратних та технічних засобів Сайту.
      </p>
      <p class="mb-5">
        Метою обробки персональних даних користувачів є складання бази даних користувачів Сайту для забезпечення
        функціонування Сайту.
      </p>
      <p class="mb-5">
        Джукі зобов'язується не розміщувати на Сайті персональні дані інших осіб без їх згоди, а також не
        використовувати персональні дані інших осіб будь-яким способом, що не відповідає вимогам чинного законодавства,
        у протиправних або протизаконних цілях, з метою отримання вигоди та/або у будь-яких інших цілях, крім тих, що
        прямо випливають з умов Договору.
      </p>
      <p class="mb-5">
        Джукі зобов'язується не розголошувати отриману від Користувача інформацію. Не вважається порушенням зобов'язань
        розголошення інформації відповідно до вимог закону.
      </p>
      <p>
        Сторони усвідомлюють, що в результаті збою в роботі Сайту, вірусної або хакерської атаки, технічних
        несправностей та інших обставин персональні дані Користувача можуть стати доступними й іншим особам. Користувач
        розуміє і погоджується з тим, що не буде висувати претензії до Джукі у зв'язку з цим.
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="5">5. Відповідальність</h3>
    <div class="mt-6">
      <p class="mb-5">
        Джукі та Користувач несуть відповідальність за порушення умов цих Правил відповідно до чинного законодавства
        України.
      </p>
      <p class="mb-5">
        Будь-які спори, що виникають з цих Правил або пов’язані з ним, вирішуються шляхом переговорів між Сторонами.
        Якщо відповідний спір неможливо вирішити шляхом переговорів, він вирішується в судовому порядку за встановленою
        підвідомчістю та підсудністю такого спору відповідно до чинного законодавства України.
      </p>
      <p class="mb-5">
        За жодних обставин Джукі не несе відповідальність перед Користувачем або перед будь-якими третіми особами за
        будь-які непрямі, випадкові, ненавмисні збитки, включаючи упущену вигоду або втрачені дані, шкоду честі,
        гідності або діловій репутації, викликані у зв'язку з використанням Сайту, вмістом Сайту або інших матеріалів,
        до яких Користувач або інші особи отримали доступ за допомогою Сайту.
      </p>
      <p class="mb-5">
        Джукі не несе відповідальності за шкоду або збитки, яких зазнав Користувач або треті особи в результаті
        помилкового розуміння або нерозуміння умов даних Умов.
      </p>
      <p>
        Джукі не несе відповідальності за достовірність інформації, опублікованої на Сайті Користувачами, та / або
        коректність висловлювань Користувачів.
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="6">6. Зміна Умов</h3>
    <div class="mt-6">
      <p class="mb-5">
        Джукі залишає за собою право змінювати або доповнювати Умови та будь-який з її Додатків в будь-який момент без
        попереднього повідомлення.
      </p>
      <p class="mb-5">
        Використання Платформи пропонується в режимі «як є», тобто в тому вигляді та обсязі, в якому вони надаються
        Джукі на дату звернення Вами до пропонованих послуг Платформи.
      </p>
      <p class="mb-5">
        Повідомлення про зміну умов розміщується в загальнодоступному місці Платформи, надсилається в приватних
        повідомленнях до Користувача та/або дублюється на електронну пошту Користувача. Обов'язок ознайомлення з чинною
        редакцією умов покладається на Вас.
      </p>
      <p>
        Продовження використання Платформи Користувачем після внесення змін та/або доповнень до Умов означає Ваше
        прийняття та згоду з такими змінами та/або доповненнями.
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="7">7. Заборонені види використання Платформою</h3>
    <div class="mt-6">
      <p>
        Усім Користувачам забороняються такі форми та види користування Платформою:
      </p>
      <ol>
        <li>для порушення чинного законодавства України;</li>
        <li>для будь-якої образливої, шахрайської та/або іншої незаконної діяльності;</li>
        <li>для булінгу, приниження людської гідності і дій, що можуть трактуватися як такі;</li>
        <li>для передачі, зберігання та/або публікації нелегальних матеріалів (включаючи матеріали, які можуть вважатися
          нецензурними);
        </li>
        <li>видавати себе за іншу людину або свідомо розміщувати неправдиві дані;</li>
        <li>порушувати права та норми охорони інтелектуальної власності згідно з цими Умовами та/або чинного
          законодавства України;
        </li>
        <li>давати в користування свій Акаунт та/або Акаунт будь-якій іншій особі, якщо інше не встановлено цими
          Умовами;
        </li>
        <li>чинити будь-які дії, що мають на меті отримання несанкціонованого доступу до Акаунту;</li>
        <li>чинити будь-які дії, що мають на меті заволодіння конфіденційною інформацією проти волі Користувача;</li>
        <li>запускати будь-які форми автовідповідача або «спам»;</li>
        <li>запускати процеси «сканування» або «павутиння»;</li>
        <li>розміщувати і поширювати будь-яку інформацію або програмне забезпечення, яке може бути використане для злому
          комп'ютерних систем або містить в комп'ютерні віруси або інші компоненти, що прирівнюються до них;
        </li>
        <li>будь-яким способом перешкоджати штатній роботі Платформи.</li>
      </ol>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="8">8. Вилучення або видалення аккаунту з Платформи</h3>
    <div class="mt-6">
      <p class="mb-5">
        Користувач може бути вилучений з Платформи на деякий час, що означає блокування можливості користування
        Платформою, або назавжди шляхом видалення його Акаунту.
      </p>
      <div class="mb-5">
        <p>Вилучення Акаунту Користувача з Платформи на деякий час або назавжди можливе, якщо:</p>
        <ol>
          <li>Користувач умисно надав недостовірну інформацію при реєстрації на Платформі;</li>
          <li>Користувач неналежно користувався та/або користується Платформою;</li>
          <li>Здійснення будь-яких інших порушень цих Умов та/або чинного законодавства України;</li>
          <li>В будь-яких інших випадках, якщо Джукі вважає такі дії як потрібні та обгрунтовані.</li>
        </ol>
      </div>

      <p>
        Користувач може видалити свій аккаунт самостійно у будь-який момент. Видалення Акаунту будь-яким чином буде
        означати розірвання цих Умов.
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="9">9. Інші умови</h3>
    <div class="mt-6">
      <p class="mb-5">
        Договір набуває чинності з моменту здійснення Користувачем реєстрації на Сайті.
      </p>
      <p class="mb-5">
        Текст даної угоди є відкритим і загальнодоступним. Чинна редакція Умов розміщується на Сайті за посиланням
        <a href="https://ukrmusic.com/terms-and-conditions" target="_blank"
         class="link">www.ukrmusic.com/terms-and-conditions.</a>
      </p>
      <p class="mb-5">
        Дані Умови може бути змінені та/або доповнені Джукі в односторонньому порядку без будь-якого спеціального
        повідомлення Користувачів про внесення таких змін та/або доповнень. Усі зміни набувають чинності з моменту їх
        публікації. Факт продовження користування Сайтом підтверджує згоду Користувача з такими змінами та/або
        доповненнями.
      </p>
      <p class="mb-5">
        Джукі може в будь-який момент і без повідомлення Користувача поступитися своїми правами за даними Умовами третій
        стороні.
      </p>
      <p class="mb-5">
        Користувач може розірвати ці Умови за власним бажанням шляхом надсилання Заявки у довільній формі до слжби
        підтримки Джукі.
      </p>
      <p class="mb-5">
        До цих Умов і відносин між Джукі та Користувачем застосовується законодавство України. Відносини, що не
        врегульовані цим Договором регулюються чинним законодавством України.
      </p>
      <p class="mb-5">
        У разі якщо будь-яка з умов або положень Договору буде визнана будь-яким судом або органом адміністративної
        влади компетентної юрисдикції недійсним, це не вплине на дійсність або можливість виконання будь-яких інших
        положень або умов Договору, які залишаються повністю дійсними, якщо інше не передбачено законодавством України.
      </p>
      <p>
        Умови укладені українською мовою, не вимагають двостороннього підписання і дійсні для Джукі та Користувача в
        електронному вигляді.
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="10">10. Зворотній зв'язок</h3>
    <div class="mt-6">
      <p>
        Якщо у Вас виникли якісь питання чи зауваження щодо використання Платформи, будь ласка, зверніться до ТОВ
        "Джукі", написавши листа на <a href="mailto:support@ukrmusic.com" class="link">support@ukrmusic.com</a> та ТОВ "Джукі" докладе всіх зусиль для того, щоби відповісти на
        ваш запит якнайшвидше.
      </p>
    </div>
    <div class="separator mt-6"></div>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditionsUkr",
  methods: {
    scrollTo(number) {
      this.$refs[number].scrollIntoView({behavior: "smooth"})
    }
  }
}
</script>

<style scoped>
li {
  line-height: 24px;
}

.terms-and-conditions-wrapper {
  background-color: #FFFFFF;
  position: relative;
  padding: 40px;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary-blue);
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.list-number {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0057B7;
  margin-right: 8px;
}

.page-content li {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  width: 100%;
  background-color: #F9F9F9;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 8px;
  cursor: pointer;
}

.link {
  text-decoration: underline;
  color: var(--primary-blue);
}

ol li {
  list-style: disc;
  margin-left: 25px;
}
</style>